<template>
  <div class="tab-cotanier">
    <x-table
      :no-data-text="CA('userProduct_check') ? '暂无数据' : '暂无数据查询权限'"
      :columns="columns"
      :data="data"
      :loading="loading"
      :config="config"
      @add="add"
      @search="search"
      @page-change="pageChange"
      :showSummary="true"
      :summaryMethod="handleSummary"
      :sum-text="'统计'"
    ></x-table>
    <!-- <div class="options-row">
      <div class="search-item">
        <span>养殖场养殖区：</span>
        <span>
          <Cascader
            transfer
            v-model="searchData.farmScene"
            :data="farmScenes"
            change-on-select
            :load-data="loadingScenes"
          ></Cascader>
        </span>
      </div>
      <div class="add-item">
        <Button @click="add" type="primary" icon="md-add">新增上报</Button>
      </div>
    </div> -->
    <Modal
      @on-visible-change="modalVisibleChange"
      v-model="modalDataShow"
      title="用水量上报"
    >
      <Form ref="form" :model="form" :rules="rules" :label-width="120">
        <FormItem label="养殖场/养殖区" prop="farmScene">
          <Cascader
            transfer
            v-model="form.farmScene"
            :data="farmScenes"
            change-on-select
            :load-data="loadingScenes"
          ></Cascader>
        </FormItem>
        <FormItem label="上报时间" prop="reportTime">
          <DatePicker v-model="form.reportTime"></DatePicker>
        </FormItem>
        <FormItem label="用水量" prop="amount">
          <Input v-model="form.amount">
            <span slot="append">m³</span>
          </Input>
        </FormItem>
      </Form>
      <div slot="footer" style="text-align: center">
        <Button @click="submit" :loading="formLoading" type="primary"
          >提交</Button
        >
        <Button
          @click="
            () => {
              modalDataShow = false;
            }
          "
          >取消</Button
        >
      </div>
    </Modal>
    <!-- <Table :columns="columns" :data="data"></Table> -->
  </div>
</template>
<script>
import moment from "moment";
import CU from "@/common/util";
export default {
  data() {
    return {
      searchData: {
        farmScene: [],
      },
      data: [],
      loading: false,
      tableData: [],
      tableColumns: [],
      page: {
        pageNo: 1,
        pageSize: 12,
        total: 0,
      },
      search_data: {},

      // 基地场景
      farmScenes: [],

      modalDataShow: false,

      form: {
        farmScene: [],
        amount: "",
        reportTime: new Date(),
      },
      rules: {
        farmScene: [
          {
            required: true,
            type: "array",
            message: "请选择养殖场/养殖区",
          },
        ],
        amount: [
          {
            required: true,
            message: "请填写用水量",
          },
        ],
        reportTime: [
          {
            required: true,
            type: "date",
            message: "请选择上报时间",
          },
        ],
      },
      formLoading: false,
      columns: [
        {
          title: "养殖场",
          key: "",
          render: (h, { row }) => {
            return <span>{row.farmName}</span>;
          },
        },
        {
          title: "养殖区",
          render: (h, { row }) => {
            return <span>{row.sceneName ? row.sceneName : "--"}</span>;
          },
        },
        {
          title: "用水量",
          key: "amount",
        },
        {
          title: "上报时间",
          key: "reportTime",
        },
      ],
    };
  },
  methods: {
    submit() {
      this.$refs.form.validate((res) => {
        if (!res) return;
        let params = {
          amount: this.form.amount,
          reportTime: moment(this.form.reportTime).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
        };
        this.form.farmScene.length > 1 &&
          (params.sceneId = this.form.farmScene[1]);
        this.form.farmScene.length > 0 &&
          (params.farmId = this.form.farmScene[0]);
        this.formLoading = true;
        console.log(params);
        this.$post(this.$api.WATER_USAGE.ADD, params)
          .then(() => {
            this.$Message.success("上报完成");
            this.modalDataShow = false;
            this.getWaterList();
          })
          .catch((e) => {})
          .finally(() => {
            this.formLoading = false;
          });
      });
    },
    modalVisibleChange(visible) {
      if (visible) return;
      this.$refs.form.resetFields();
      this.formLoading = false;
    },
    add() {
      this.modalDataShow = true;
    },
    // getList() {
    //   this.loading = true;
    //   this.$post(this.$api.WATER_USAGE.LIST, {
    //     pageNo: this.page.pageNo,
    //     pageSize: this.page.pageSize,
    //   }).then((rs) => {
    //     this.page.total = +rs.total;
    //     this.tableData = rs.list;
    //   });
    // },
    // 获取基地场景
    // getFarmScene() {
    //   this.$post(this.$api.BASE_MANAGE.LIST, {
    //     pageNo: 1,
    //     pageSize: 9999,
    //   }).then((res) => {
    //     this.farmScenes = res.list.map((item) => ({
    //       value: item.id,
    //       label: item.name,
    //       children: [],
    //       loading: false,
    //     }));
    //   });
    // },
    getFarmScene() {
      this.$post(this.$api.PRODUCTION_AREA.FARMSCENE, {
        siteType: "2",
      }).then((res) => {
        this.farmScenes = CU.formatTreeData(res, "id", "name", "sceneList");
      });
    },
    // 获取养殖区
    loadingScenes(item, callback) {
      item.loading = true;
      this.$post(this.$api.PRODUCTION_AREA.LIST, {
        farmId: item.value,
        pageNo: 1,
        pageSize: 9999,
        siteType: "2",
      })
        .then((res) => {
          if (!res.list || res.list.length === 0) {
            item.children = [
              {
                disabled: true,
                value: -1,
                label: "无",
              },
            ];
          } else {
            item.children = res.list.map((item) => ({
              value: item.id,
              label: item.name,
            }));
          }
        })
        .finally(() => {
          item.loading = false;
          callback();
        });
    },
    //获取用水量列表
    getWaterList() {
      this.loading = true;
      this.$post(this.$api.WATER_USAGE.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.search_data,
      }).then((res) => {
        this.data = res.list;
        this.page.total = +res.total;
        this.loading = false;
      });
    },
    handleSummary({ columns, data }) {
      const sums = {};
      columns.forEach((column, index) => {
        const key = column.key;
        if (index === 0) {
          sums[key] = {
            key,
            value: "统计",
          };
          return;
        }
        const values = data.map((item) => Number(item[key]));
        if (column.key == "amount") {
          if (!values.every((value) => isNaN(value))) {
            const v = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[key] = {
              key,
              value: v,
            };
          } else {
            sums[key] = {
              key,
              value: "",
            };
          }
        } else {
          sums[key] = {
            key,
            value: "",
          };
        }
      });
      return sums;
    },
    search(data) {
      let obj = {
        farmId: data.area[0],
        sceneId: data.area[1],
        categoryId: data.categoryId,
      };
      this.search_data = obj;
      this.page.pageNo = 1;
      this.getWaterList();
    },
    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getWaterList();
    },
  },
  computed: {
    config() {
      let config = {
        filter: {
          add: {
            addBtnName: "新增用水量",
            ca: "water_add",
          },
          width: 200,
          filterBox: [
            {
              conditionName: "养殖场",
              component: "cascader",
              data: this.farmScenes,
              field: "area",
              defaultValue: [],
            },
          ],
        },
        page: this.page,
      };
      return config;
    },
  },
  mounted() {
    this.getFarmScene();
    this.getWaterList();
  },
};
</script>
<style lang='less' scoped>
@import url("./index.less");
</style>